const config = window.__PORTAL__.config;

export const apiManagement = config["REACT_APP_API_MANAGEMENT"];
export const ocpApimSubscriptionKey = config["REACT_APP_OCP_APIM_SUBSCRIPTION_KEY"];
export const googleApiKey = config["REACT_APP_GOOGLE_API_KEY"];
export const leadMarketAffiliateCode = config["REACT_APP_LEAD_MARKET_AFFILIATE_CODE"];
export const leadMarketMode = config["REACT_APP_LEAD_MARKET_MODE"];
export const runConfiguration = config["REACT_APP_ENVIRONMENT_RUN_CONFIGURATION"];
export const bankStatementIframeUrl = config["REACT_APP_BS_IFRAME_URL"];
export const portalDebug = config["REACT_APP_PORTAL_DEBUG"];
export const authUrl = config["REACT_APP_AUTH_URL"];
export const appUrl = config["REACT_APP_URL"];
export const authClientId = config["REACT_APP_CLIENT_ID"];
export const publicWebsite = config["REACT_APP_PUBLIC_WEBSITE"];
export const newCustomerForm = config["REACT_APP_NEW_CUSTOMER_FORM"];
export const privacyPolicy = config["FGF_PRIVACY_POLICY"];
export const termsConditions = config["FGF_TERMS_AND_CONDITIONS"];
export const referralForm = config["REACT_APP_REFERRAL"];
export const contentManagementUrl = config["REACT_APP_CONTENT_URL"];
export const contentToken = config["REACT_APP_CONTENT_TOKEN"];
export const gtmId = config["GTM_ID"];
export const gtmAuth = config["GTM_AUTH"];
export const gtmPreview = config["GTM_PREVIEW"];